/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';
import ShowMeLoveTerms from './pages/ShowMeLoveTerms';

const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const Heritage = universal(import('./pages/Heritage'));
const Product = universal(import('./pages/Product'));
const Category = universal(import('./pages/Category'));
const ShowMeLove = universal(import('./pages/ShowMeLove'));
const SantoriniSunrise = universal(import('./pages/SantoriniSunrise'));
const PartyLove = universal(import('./pages/PartyLove'));
const BrisaCubana = universal(import('./pages/BrisaCubana'));

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    productOverrides: {
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/heritage',
      exact: true,
      id: 'heritage',
      title: 'Heritage',
      component: Heritage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/heritage').default
      ),
    },
    {
      path: '/:locale/fragrances',
      exact: true,
      id: 'fragrances',
      title: 'Fragrances',
      component: Category,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/category').default
      ),
    },
    {
      path: '/:locale/show-me-love',
      exact: true,
      id: 'show-me-love',
      title: 'Show Me Love',
      component: ShowMeLove,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/show-me-love').default
      ),
    },
    {
      path: '/:locale/show-me-love-contest-terms-and-conditions',
      exact: true,
      id: 'show-me-love-contest-terms-and-conditions',
      title: 'Show Me Love Contest Terms And Conditions',
      component: ShowMeLoveTerms,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/show-me-love-terms').default
      ),
    },
    {
      path: '/:locale/santorini-sunrise',
      exact: true,
      id: 'santorini-sunrise',
      title: 'Santorini Sunrise',
      component: SantoriniSunrise,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/santorini-sunrise').default
      ),
    },
    {
      path: '/:locale/party-love',
      exact: true,
      id: 'party-love',
      title: 'Escada Party Love',
      component: PartyLove,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/party-love').default
      ),
    },
    {
      path: '/:locale/brisa-cubana',
      exact: true,
      id: 'brisa-cubana',
      title: 'Escada Brisa Cubana',
      component: BrisaCubana,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/brisa-cubana').default
      ),
    },
  ],

  routesToRemove: [
    'about',
    'category',
    'subcategory',
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
