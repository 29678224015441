import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import R from 'ramda';
import { HeroFullBlock } from '@cotyorg/ccx-organism-hero';
import StandardLayout from '../../templates/StandardLayout';

const Term = ({ term }) => (
  <section className="cell">
    <div className="show-me-love-terms-page__term-section">
      <h3 className="show-me-love-terms-page__term-heading">
        {R.prop('heading', term)}
      </h3>

      <div
        className="show-me-love-terms-page__term-description"
        dangerouslySetInnerHTML={{ __html: R.prop('description', term) }}
      />
    </div>
  </section>
);

const ShowMeLoveTerms = ({ sectionHeading, terms }) => (
  <div className="show-me-love-terms-page">
    <StandardLayout>
      <section className="hero-full-block--main">
        <HeroFullBlock
          preloadImage
          heroStoreDataKey="hero"
          layout="vertical"
          theme="secondary"
          isUnderlined={false}
        />
      </section>

      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <section className="cell">
            <div className="show-me-love-terms-page__heading-section">
              <h2 className="show-me-love-terms-page__heading">
                {R.prop('heading', sectionHeading)}
              </h2>

              <h2 className="show-me-love-terms-page__heading">
                {R.prop('subheading', sectionHeading)}
              </h2>

              <div
                dangerouslySetInnerHTML={{
                  __html: R.prop('introduction', sectionHeading),
                }}
              />
            </div>
          </section>

          {R.prop('length', terms) > 0 &&
            R.map(
              (term) => <Term term={term} key={R.prop('heading', term)} />,
              terms
            )}
        </div>
      </div>
    </StandardLayout>
  </div>
);

Term.propTypes = {
  term: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

ShowMeLoveTerms.propTypes = {
  sectionHeading: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    introduction: PropTypes.string,
  }),
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default connect(({ content }) => ({
  ...R.pick(['sectionHeading', 'terms'], content),
}))(ShowMeLoveTerms);
