import React, { Fragment } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from '@cotyorg/ccx-atoms/Link';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterStreamlined } from '@cotyorg/ccx-organism-footer';
import { Social } from '@cotyorg/ccx-molecules/Social';
import { readJSONFromScriptTag } from '../../utils';
import useScript from '../../hooks/useScripts';

const { SWAVEN_TOKEN } = readJSONFromScriptTag({ id: 'clientEnv' });

const StandardLayout = ({
  cookieContent,
  isServedFromLegacySite,
  sectionLinks,
  children,
  isOneTrustEnabled,
}) => (
  <>
    {useScript({
      url: `/ccx-files/assets/swaven.js?swavenToken=${SWAVEN_TOKEN}`,
    })}

    <HeaderTwoRow
      fixed
      logoSrc="/ccx-files/assets/logo.png"
      chevronIconSrc="/ccx-files/assets/chevron-down.svg"
      leftChevronIconSrc="/ccx-files/assets/arrow-left-gold.svg"
      rightChevronIconSrc="/ccx-files/assets/arrow-right-gold.svg"
      closeIconSrc="/ccx-files/assets/cross.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      logoPosition="center"
      navType="featuredProducts"
      logoWidth={343}
      logoHeight={53}
    />

    {children}

    <div className="pre-footer text--center">
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell xsmall-16">
            <Link
              {...(R.path(
                ['sectionLink1', 'openInNewWindow'],
                sectionLinks
              ) && { target: '_blank' })}
              link={R.path(['sectionLink1', 'url'], sectionLinks)}
              extraClass="button button--primary"
            >
              {R.path(['sectionLink1', 'text'], sectionLinks)}
            </Link>
          </div>

          <div className="cell xsmall-16">
            <Social
              socialIconSrc={{
                facebook: '/ccx-files/assets/facebook.svg',
                twitter: '/ccx-files/assets/twitter.svg',
                instagram: '/ccx-files/assets/instagram.svg',
                youtube: '/ccx-files/assets/youtube.svg',
                pinterest: '/ccx-files/assets/pinterest.svg',
              }}
              socialAlignment="center"
              linkTarget="_blank"
            />
          </div>
        </div>
      </div>
    </div>

    <FooterStreamlined
      isServedFromLegacySite={isServedFromLegacySite}
      countrySelector={{
        visible: true,
      }}
      chevronIconSrc="/ccx-files/assets/chevron-down-white.svg"
      showCountrySelector
    />

    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </>
);

StandardLayout.propTypes = {
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }).isRequired,
  isServedFromLegacySite: PropTypes.bool,
  sectionLinks: PropTypes.objectOf(PropTypes.object),
  children: PropTypes.node,
  isOneTrustEnabled: PropTypes.bool,
};

export default connect(({ content, breakpoints, status }) => ({
  cookieContent: R.prop('cookie')(content),
  currentBreakpoint: R.prop('current')(breakpoints),
  isServedFromLegacySite: R.prop('isServedFromLegacySite')(status),
  sectionLinks: R.prop('sectionLinks', content),
  isOneTrustEnabled: R.prop('isOneTrustEnabled')(content),
}))(StandardLayout);
