import R from 'ramda';

export const isSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('small');

export const isLargeBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('large');

export const truncate = (maxLen, str) => {
  if (!str) return '';

  const input = String(str);
  if (input.length <= maxLen) return input;
  return `${input.substr(0, input.lastIndexOf(' ', maxLen))}...`;
};

export const truncateCardText = ({ items, maxTextLength }) =>
  Array.isArray(items) &&
  items.map((item) => ({
    ...item,
    description: R.compose(
      R.curry(truncate)(maxTextLength),
      R.prop('description')
    )(item),
  }));

export const readJSONFromScriptTag = ({ id }) => {
  try {
    return JSON.parse(document.querySelector(`#${id}`).textContent);
  } catch (err) {
    return {};
  }
};
